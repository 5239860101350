.admin-panel {
  display: flex;
  height: 100vh; /* Full height */
}

.sidebar {
  position: fixed; /* Fixed position */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 250px; /* Set the width of the sidebar */
  height: 100%; /* Full height */
  background-color: #f8f9fa; /* Light background */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Scroll if content overflows */
}

.sidebar h2 {
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin: 10px 0;
}

.sidebar a {
  text-decoration: none;
  color: #007bff; /* Bootstrap primary color */
}

.main-content {
  background-color: #ffffff; /* White background */
  flex: 1; /* Take up remaining space */
  height: 100vh; /* Full height */
  overflow: hidden;
  overflow-y: auto; /* Scroll if content overflows */
}

.main-content-header {
  height: 80px;
  padding: 20px;
  margin: 0;
  color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #cbcbcb;
}
.main-content-body {
  padding: 20px;
  background-color: #ffffff; /* White background */
  flex: 1; /* Take up remaining space */
  height: calc(100vh - 80px); /* Full height */
  overflow: hidden;
  overflow-y: auto; /* Scroll if content overflows */
}
