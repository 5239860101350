.DropFileModal {
}

.drop-area {
  height: 50vh;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.3s;
}

.drop-area:hover {
  border-color: #007bff;
}

.drop-area.dragging {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}
