.SearhUserOrCompany {
  position: relative; /* Positionne le conteneur parent pour utiliser le positionnement absolu des suggestions */
}
.CompanySelector {
  position: relative; /* Positionne le conteneur parent pour utiliser le positionnement absolu des suggestions */
}
.UserSelector {
  position: relative; /* Positionne le conteneur parent pour utiliser le positionnement absolu des suggestions */
}

.search-box {
  position: relative; /* Nécessaire pour positionner les suggestions par rapport à la boîte de recherche */
}

.autocomplete-results {
  position: absolute; /* Permet à la boîte de suggestions de se superposer au-dessus des autres éléments */
  top: 100%; /* Positionne la boîte directement en dessous de la boîte de recherche */
  left: 0;
  width: 100%;
  max-height: 200px; /* Hauteur maximale pour la boîte de suggestions */
  overflow-y: auto; /* Permet le défilement vertical si le contenu dépasse la hauteur maximale */
  background-color: white; /* Fond blanc pour la boîte de suggestions */
  border: 1px solid #ddd; /* Bordure pour délimiter la boîte de suggestions */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Ombre portée pour donner un effet de profondeur */
  z-index: 10; /* S'assure que la boîte de suggestions est au-dessus des autres éléments */
}

.autocomplete-results ul {
  list-style: none; /* Supprime les puces de la liste */
  padding: 0;
  margin: 0;
}

.autocomplete-results li {
  padding: 10px; /* Espacement interne pour chaque élément de la liste */
  cursor: pointer; /* Change le curseur en pointeur pour indiquer que l'élément est cliquable */
}

.autocomplete-results li:hover {
  background-color: #f0f0f0; /* Fond clair au survol pour améliorer l'interaction utilisateur */
}

.selected-users {
  margin-top: 10px; /* Espacement au-dessus de la liste des utilisateurs sélectionnés */
}

.badge {
  display: inline-flex; /* Affiche les badges en ligne */
  align-items: center; /* Centre les éléments enfants verticalement */
  background-color: #007bff; /* Couleur de fond des badges */
  color: white; /* Couleur du texte des badges */
  border-radius: 12px; /* Arrondir les coins des badges */
  padding: 5px 10px; /* Espacement interne des badges */
  margin: 2px; /* Espacement entre les badges */
}

.badge .icon {
  margin-left: 5px; /* Espacement à gauche de l'icône pour séparer du texte */
  cursor: pointer; /* Change le curseur en pointeur pour indiquer que l'icône est cliquable */
}
