/*
  This file is used to style the VerifyCodeForm component
*/

.VerifyCodeForm form {
  width: 400px;
}

@media screen and (max-width: 600px) {
  .VerifyCodeForm form {
    width: 90vw;
  }
}
