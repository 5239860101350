.home-page{
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
header h1 {
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: rgb(172, 62, 62);
}
header {
    padding: 10px 20px;
    background-color: #eeeeee;
    color: rgb(36, 36, 36);
    opacity: 0.9;
    /* border-bottom: 1px solid rgb(36, 36, 36); */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}