body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.px1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.group {
  display: flex;
  justify-content: space-between;
}
button,
.btn {
  color: white;
  border: none;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.125rem; /* lg size */
}
/* Style général pour les inputs */
.form-control {
  border-radius: 0px; /* Coins arrondis */
  border: 1px solid #d9d9d9; /* Bordure grise */
  box-shadow: none; /* Pas d'ombre */
  transition: all 0.3s ease; /* Transition douce */
  height: 50px;
}

input[type='checkbox'] {
  height: auto;
}

/* Changer la couleur de la bordure et de l'ombre au focus */
.form-control:focus {
  border-color: #1890ff; /* Bordure bleue Antd */
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.2); /* Ombre légère */
}

/* Styles pour les sélecteurs */
.select {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

/* Styles pour les boutons de sélection */
.select:focus {
  border-color: #1890ff;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.2);
}

/* Style pour les labels */
label {
  font-weight: 600; /* Gras */
  margin-bottom: 0.5rem; /* Espace en bas */
}

/* Styles pour les textareas */
textarea.form-control {
  height: auto; /* Hauteur automatique */
  min-height: 100px; /* Hauteur minimale */
}

/* Styles pour les badges */
.badge {
  border-radius: 12px; /* Coins arrondis */
  padding: 0.5rem 0.75rem; /* Espacement interne */
}

/* Style pour les placeholders */
::placeholder {
  color: #bfbfbf; /* Couleur du texte de placeholder */
  opacity: 1; /* Assurez-vous qu'il est complètement opaque */
}

.flex-1 {
  flex: 1;
}
